
Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal,
  objectEnumValues,
  makeStrictEnum,
  Public,
  getRuntime
} = require('./runtime/index-browser.js')


const Prisma = {}

exports.Prisma = Prisma
exports.$Enums = {}

/**
 * Prisma Client JS version: 5.19.1
 * Query Engine version: 69d742ee20b815d88e17e54db4a2a7a3b30324e3
 */
Prisma.prismaVersion = {
  client: "5.19.1",
  engine: "69d742ee20b815d88e17e54db4a2a7a3b30324e3"
}

Prisma.PrismaClientKnownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientKnownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientUnknownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientRustPanicError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientRustPanicError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientInitializationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientInitializationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientValidationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientValidationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.NotFoundError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`NotFoundError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`sqltag is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.empty = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`empty is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.join = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`join is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.raw = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`raw is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.validator = Public.validator

/**
* Extensions
*/
Prisma.getExtensionContext = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.getExtensionContext is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.defineExtension = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.defineExtension is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = objectEnumValues.instances.DbNull
Prisma.JsonNull = objectEnumValues.instances.JsonNull
Prisma.AnyNull = objectEnumValues.instances.AnyNull

Prisma.NullTypes = {
  DbNull: objectEnumValues.classes.DbNull,
  JsonNull: objectEnumValues.classes.JsonNull,
  AnyNull: objectEnumValues.classes.AnyNull
}

/**
 * Enums
 */

exports.Prisma.TransactionIsolationLevel = makeStrictEnum({
  ReadUncommitted: 'ReadUncommitted',
  ReadCommitted: 'ReadCommitted',
  RepeatableRead: 'RepeatableRead',
  Serializable: 'Serializable'
});

exports.Prisma.FormsortFormDataScalarFieldEnum = {
  id: 'id',
  responder_uuid: 'responder_uuid',
  finalized: 'finalized',
  formsort_form_type: 'formsort_form_type',
  variant_label: 'variant_label',
  variant_uuid: 'variant_uuid',
  flow_label: 'flow_label',
  created_at: 'created_at',
  answers: 'answers',
  descriptions: 'descriptions',
  log: 'log',
  welkin_assessment_uuid: 'welkin_assessment_uuid',
  cognito_sub: 'cognito_sub',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.FileScalarFieldEnum = {
  id: 'id',
  path: 'path',
  extension: 'extension',
  url: 'url',
  size: 'size',
  modelId: 'modelId',
  model: 'model',
  type: 'type'
};

exports.Prisma.PostScalarFieldEnum = {
  id: 'id',
  type: 'type',
  body: 'body',
  title: 'title',
  instructor: 'instructor',
  url: 'url',
  author: 'author',
  sequence: 'sequence'
};

exports.Prisma.PlaylistsScalarFieldEnum = {
  id: 'id',
  playlistId: 'playlistId',
  sequence: 'sequence'
};

exports.Prisma.MoodTrackingScalarFieldEnum = {
  id: 'id',
  patientId: 'patientId',
  score: 'score',
  details: 'details',
  date: 'date',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.NmiLogScalarFieldEnum = {
  id: 'id',
  source: 'source',
  patientId: 'patientId',
  params: 'params',
  data: 'data',
  createdAt: 'createdAt'
};

exports.Prisma.PreAuthTransactionsScalarFieldEnum = {
  id: 'id',
  transactionId: 'transactionId',
  patientId: 'patientId',
  status: 'status',
  error: 'error',
  capturedAt: 'capturedAt',
  capturedSource: 'capturedSource',
  createdAt: 'createdAt'
};

exports.Prisma.ZoomLogScalarFieldEnum = {
  id: 'id',
  event: 'event',
  payload: 'payload',
  zoomTimestamp: 'zoomTimestamp',
  createdAt: 'createdAt'
};

exports.Prisma.ClinicianScalarFieldEnum = {
  id: 'id',
  firstName: 'firstName',
  lastName: 'lastName',
  timetapId: 'timetapId',
  welkinId: 'welkinId',
  disabled: 'disabled',
  npi: 'npi',
  drFirstName: 'drFirstName',
  pinToTop: 'pinToTop',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  createdBy: 'createdBy',
  updatedBy: 'updatedBy'
};

exports.Prisma.EncounterTypeScalarFieldEnum = {
  id: 'id',
  title: 'title',
  welkinEncounterName: 'welkinEncounterName',
  timetapId: 'timetapId',
  role: 'role',
  hasInPersonOption: 'hasInPersonOption',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  createdBy: 'createdBy',
  updatedBy: 'updatedBy'
};

exports.Prisma.StateScalarFieldEnum = {
  id: 'id',
  name: 'name',
  shortName: 'shortName',
  active: 'active',
  providerNpi: 'providerNpi',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  createdBy: 'createdBy',
  updatedBy: 'updatedBy'
};

exports.Prisma.ClinicianEncounterStateScalarFieldEnum = {
  clinicianId: 'clinicianId',
  encounterId: 'encounterId',
  stateId: 'stateId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  createdBy: 'createdBy',
  updatedBy: 'updatedBy'
};

exports.Prisma.InsuranceScalarFieldEnum = {
  id: 'id',
  internalName: 'internalName',
  name: 'name',
  stateId: 'stateId',
  payerId: 'payerId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  createdBy: 'createdBy',
  updatedBy: 'updatedBy',
  order: 'order'
};

exports.Prisma.InsuranceEncounterScalarFieldEnum = {
  encounterId: 'encounterId',
  insuranceId: 'insuranceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  createdBy: 'createdBy',
  updatedBy: 'updatedBy'
};

exports.Prisma.InsuranceClinicianScalarFieldEnum = {
  clinicianId: 'clinicianId',
  insuranceId: 'insuranceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  createdBy: 'createdBy',
  updatedBy: 'updatedBy'
};

exports.Prisma.InsuranceRequestScalarFieldEnum = {
  id: 'id',
  requestData: 'requestData',
  responseGeneralData: 'responseGeneralData',
  responseSpecificData: 'responseSpecificData',
  eligibilityGuess: 'eligibilityGuess',
  eligibilityGuessError: 'eligibilityGuessError',
  eligibilityChecked: 'eligibilityChecked',
  eligibilityNote: 'eligibilityNote',
  formsortUUID: 'formsortUUID',
  welkinPatientId: 'welkinPatientId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  createdBy: 'createdBy',
  updatedBy: 'updatedBy'
};

exports.Prisma.PrecisionWebhookEventScalarFieldEnum = {
  id: 'id',
  test: 'test',
  type: 'type',
  externalId: 'externalId',
  referenceId: 'referenceId',
  medicationId: 'medicationId',
  patientFirstName: 'patientFirstName',
  patientLastName: 'patientLastName',
  patientBirthDate: 'patientBirthDate',
  doctorNpi: 'doctorNpi',
  doctorFirstName: 'doctorFirstName',
  doctorLastName: 'doctorLastName',
  doctorAddress: 'doctorAddress',
  fillExternalId: 'fillExternalId',
  fillReferenceId: 'fillReferenceId',
  prescriptionExternalId: 'prescriptionExternalId',
  prescriptionReferenceId: 'prescriptionReferenceId',
  totalPackages: 'totalPackages',
  packageNumber: 'packageNumber',
  carrier: 'carrier',
  trackingNumber: 'trackingNumber',
  shippingMethod: 'shippingMethod',
  shippedDate: 'shippedDate',
  deliveredDate: 'deliveredDate',
  estimatedDeliveryDate: 'estimatedDeliveryDate',
  reasonCode: 'reasonCode',
  eventFor: 'eventFor',
  hash: 'hash',
  signatureValid: 'signatureValid',
  other: 'other',
  createdAt: 'createdAt'
};

exports.Prisma.MedicationPrescriptionScalarFieldEnum = {
  id: 'id',
  patientId: 'patientId',
  cdtId: 'cdtId',
  status: 'status',
  externalPatientId: 'externalPatientId',
  drFirstClinicianName: 'drFirstClinicianName',
  prescribingClinicianId: 'prescribingClinicianId',
  precisionMedicationId: 'precisionMedicationId',
  error: 'error',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  patientName: 'patientName',
  pharmacyName: 'pharmacyName',
  drugNdcId: 'drugNdcId',
  medicationName: 'medicationName',
  dosage: 'dosage',
  dosageUnit: 'dosageUnit',
  patientNotes: 'patientNotes',
  quantity: 'quantity',
  quantityUnit: 'quantityUnit',
  startDate: 'startDate',
  stopDate: 'stopDate',
  lastWrittenDate: 'lastWrittenDate',
  active: 'active'
};

exports.Prisma.MedicationPrescriptionRequestScalarFieldEnum = {
  id: 'id',
  medicationPrescriptionId: 'medicationPrescriptionId',
  request: 'request',
  response: 'response'
};

exports.Prisma.MedicationPrescriptionFillScalarFieldEnum = {
  id: 'id',
  request: 'request',
  response: 'response',
  createdBy: 'createdBy',
  createdAt: 'createdAt'
};

exports.Prisma.MedicationPrescriptionFillPrescriptionScalarFieldEnum = {
  medicationPrescriptionFillId: 'medicationPrescriptionFillId',
  medicationPrescriptionId: 'medicationPrescriptionId'
};

exports.Prisma.PrecisionMedicationScalarFieldEnum = {
  precisionId: 'precisionId',
  ndcId: 'ndcId',
  name: 'name',
  dosage: 'dosage',
  dosageUnit: 'dosageUnit'
};

exports.Prisma.SortOrder = {
  asc: 'asc',
  desc: 'desc'
};

exports.Prisma.JsonNullValueInput = {
  JsonNull: Prisma.JsonNull
};

exports.Prisma.NullableJsonNullValueInput = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull
};

exports.Prisma.QueryMode = {
  default: 'default',
  insensitive: 'insensitive'
};

exports.Prisma.JsonNullValueFilter = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull,
  AnyNull: Prisma.AnyNull
};

exports.Prisma.NullsOrder = {
  first: 'first',
  last: 'last'
};
exports.FileModelEnum = exports.$Enums.FileModelEnum = {
  post: 'post'
};

exports.PostTypeEnum = exports.$Enums.PostTypeEnum = {
  focus: 'focus',
  learn: 'learn'
};

exports.NmiLogSource = exports.$Enums.NmiLogSource = {
  hook: 'hook',
  request: 'request'
};

exports.PreAuthTransactionStatus = exports.$Enums.PreAuthTransactionStatus = {
  Pending: 'Pending',
  Captured: 'Captured',
  Reverted: 'Reverted',
  Error: 'Error'
};

exports.WelkinRoleDb = exports.$Enums.WelkinRoleDb = {
  PrescribingClinician: 'PrescribingClinician',
  Therapist: 'Therapist'
};

exports.PrescriptionStatus = exports.$Enums.PrescriptionStatus = {
  prescription_not_created: 'prescription_not_created',
  prescription_created: 'prescription_created',
  prescription_received: 'prescription_received',
  prescription_error: 'prescription_error',
  prescription_warning: 'prescription_warning',
  prescription_canceled: 'prescription_canceled',
  prescription_resent: 'prescription_resent',
  fill_created: 'fill_created',
  fill_shipped: 'fill_shipped',
  fill_delivered: 'fill_delivered',
  fill_warning: 'fill_warning'
};

exports.Prisma.ModelName = {
  FormsortFormData: 'FormsortFormData',
  File: 'File',
  Post: 'Post',
  Playlists: 'Playlists',
  MoodTracking: 'MoodTracking',
  NmiLog: 'NmiLog',
  PreAuthTransactions: 'PreAuthTransactions',
  ZoomLog: 'ZoomLog',
  Clinician: 'Clinician',
  EncounterType: 'EncounterType',
  State: 'State',
  ClinicianEncounterState: 'ClinicianEncounterState',
  Insurance: 'Insurance',
  InsuranceEncounter: 'InsuranceEncounter',
  InsuranceClinician: 'InsuranceClinician',
  InsuranceRequest: 'InsuranceRequest',
  PrecisionWebhookEvent: 'PrecisionWebhookEvent',
  MedicationPrescription: 'MedicationPrescription',
  MedicationPrescriptionRequest: 'MedicationPrescriptionRequest',
  MedicationPrescriptionFill: 'MedicationPrescriptionFill',
  MedicationPrescriptionFillPrescription: 'MedicationPrescriptionFillPrescription',
  PrecisionMedication: 'PrecisionMedication'
};

/**
 * This is a stub Prisma Client that will error at runtime if called.
 */
class PrismaClient {
  constructor() {
    return new Proxy(this, {
      get(target, prop) {
        let message
        const runtime = getRuntime()
        if (runtime.isEdge) {
          message = `PrismaClient is not configured to run in ${runtime.prettyName}. In order to run Prisma Client on edge runtime, either:
- Use Prisma Accelerate: https://pris.ly/d/accelerate
- Use Driver Adapters: https://pris.ly/d/driver-adapters
`;
        } else {
          message = 'PrismaClient is unable to run in this browser environment, or has been bundled for the browser (running in `' + runtime.prettyName + '`).'
        }
        
        message += `
If this is unexpected, please open an issue: https://pris.ly/prisma-prisma-bug-report`

        throw new Error(message)
      }
    })
  }
}

exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)
