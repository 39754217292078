export const BreathingExercises = {
   WakeUp: '0',
   Relax: '1',
   Focus: '2',
   ReleaseAnxiety: '3',
   Unwind: '4',
   Sleep: '5',
} as const;

export type BreathingExercise =
   (typeof BreathingExercises)[keyof typeof BreathingExercises];
